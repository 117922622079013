<template>
  <div class="weChat"></div>
</template>
<script>
export default {
  data() { 
    return {};
  },
  created() {
    this.$router.push({
      path: "/index",
    });
    window.localStorage.setItem("path", '/index');
  },
  methods: {},
  mounted() {},
};
</script>
<style scoped>
</style>
